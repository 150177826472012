import { createPinia } from 'pinia'
import { createApp } from 'vue'

import App from '@/App.vue'
import i18n from '@/i18n'
import router from '@/router'

import '@/assets/scss/main.scss'

/* Font Awesome */

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBars,
  faBowlingBall,
  faDeleteLeft,
  faEnvelope,
  faFileContract,
  faMagnifyingGlass,
  faPhone,
  faSpinner,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faBars,
  faBowlingBall,
  faDeleteLeft,
  faEnvelope,
  faFileContract,
  faMagnifyingGlass,
  faPhone,
  faSpinner,
  faXmark,
)

/* Creating the app */

createApp(App)
  .use(createPinia())
  .use(router())
  .use(i18n)
  .component('FontAwesomeIcon', FontAwesomeIcon)
  .mount('#app')
