import { defineStore } from 'pinia'

import { ApiUrls, PublicApi } from '@/api'
import {
  FarmClubDeal,
  FarmClubDealsState,
} from '@/store/public/farm-club-deals.types'

export const useFarmClubDealsStore = defineStore('farm-club-deals', {
  state(): FarmClubDealsState {
    return {
      deals: [],
    }
  },

  actions: {
    async fetch(): Promise<void> {
      const url = ApiUrls.farmClubDeals()
      const { data } = await PublicApi.get<FarmClubDeal[]>(url)

      this.deals = data
    },
  },
})
