import axios from 'axios'
import isNil from 'lodash/isNil'

export const getErrorMessage = (error?: Error): string => {
  if (isNil(error)) {
    return 'Unknown error'
  }

  if (!axios.isAxiosError(error)) {
    return error.toString()
  }

  return (error.response?.data.detail ?? error.message) as string
}
