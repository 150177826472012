import { defineStore } from 'pinia'

import { Api, ApiUrls } from '@/api'
import { UserData, UserState } from '@/store/bowler-app/user.types'

export const useUserStore = defineStore('user', {
  state(): UserState {
    return {
      id: undefined,
      email: undefined,
      firstName: undefined,
      lastName: undefined,
      bowlerId: undefined,
    }
  },

  actions: {
    clear(): void {
      this.$reset()
    },

    async fetch(): Promise<void> {
      const url = ApiUrls.currentUser()
      const { data } = await Api.get<UserData>(url)

      this.id = data.id
      this.email = data.email
      this.firstName = data.first_name
      this.lastName = data.last_name
      this.bowlerId = data.bowler
    },
  },
})
