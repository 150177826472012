import * as dateFns from 'date-fns'
import { defineStore } from 'pinia'

import { ApiUrls, PublicApi } from '@/api'
import {
  CompetitionData,
  CompetitionIndividualStandingsSlipData,
  CompetitionState,
  CompetitionTeamStandingsSlipData,
} from '@/store/public/competition.types'

export const useCompetitionStore = defineStore('competition', {
  state(): CompetitionState {
    return {
      id: undefined,
      name: undefined,
      dates: undefined,
      details: undefined,
      standings: {
        individual: undefined,
        team: undefined,
      },
    }
  },

  actions: {
    async fetch(id: string): Promise<void> {
      const url = ApiUrls.competition(id)
      const { data } = await PublicApi.get<CompetitionData>(url)

      this.id = data.id
      this.name = data.name
      this.dates = {
        start: dateFns.parseISO(data.start_date),
        end: dateFns.parseISO(data.end_date),
        update: dateFns.parseISO(data.last_added_score_date ?? ''),
      }
      this.details = {
        associationName: data.association_name,
        bowlingAlleyNames: data.bowling_alley_names,
        supervisor: data.supervisor,
        ruleCount: data.rule_count,
      }
      this.standings = {
        individual: [],
        team: undefined,
      }
    },

    async fetchIndividualStandings(id: string): Promise<void> {
      const url = ApiUrls.competitionStandings(id)
      const { data } = await PublicApi.get<
        CompetitionIndividualStandingsSlipData[]
      >(url)

      this.standings.individual = data.map((entry) => ({
        label: entry.label,
        showBestScoreOnly: /^(1xn|all|kaikki)$/.test(entry.label.toLowerCase()),
        bowlers: entry.bowlers.map((bowler) => ({
          fullName: bowler.bowler_full_name,
          klassName: bowler.bowler_klass_name ?? null,
          membership: {
            associationName: bowler.bowler_association ?? null,
            clubName: bowler.bowler_club ?? null,
          },
          bestScore: {
            total: bowler.bowler_score,
            lastGame: bowler.bowler_score_last_game,
            handicap: bowler.bowler_handicap,
            strikeCount: bowler.bowler_strike_count,
          },
          ruleScore: {
            total: bowler.competition_rule_value ?? null,
            calculation: bowler.competition_rule_detail ?? null,
            rank: bowler.competition_rule_rank ?? null,
            ruleName: bowler.competition_rule_name ?? null,
            lastGameScore: bowler.last_game_score_detail ?? null,
          },
        })),
      }))
    },

    async fetchTeamStandings(id: string): Promise<void> {
      const url = ApiUrls.competitionTeamStandings(id)
      const { data } = await PublicApi.get<CompetitionTeamStandingsSlipData[]>(
        url,
      )

      this.standings.team = data.map((entry) => ({
        label: entry.label,
        teams: entry.teams.map((team) => ({
          name: team.team_name,
          score: team.team_score,
          average: team.team_average,
          members: team.team_members.map((member) => ({
            fullName: member.bowler_full_name,
            score: member.bowler_score,
            handicap: member.bowler_handicap,
          })),
        })),
      }))
    },
  },
})
