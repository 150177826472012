import isNil from 'lodash/isNil'
import { defineStore } from 'pinia'

import { ApiUrls, PublicApi, setApiToken } from '@/api'
import { AuthData, AuthPayload, AuthState } from '@/store/bowler-app/auth.types'

const persistentStorageKey = 'bowling.stores.auth'

export const useAuthStore = defineStore('auth', {
  state(): AuthState {
    const persistentState = JSON.parse(
      localStorage.getItem(persistentStorageKey) ?? '{}',
    )

    if (!isNil(persistentState.accessToken)) {
      setApiToken(persistentState.accessToken)
    }

    return {
      accessToken: persistentState.accessToken,
      refreshToken: persistentState.refreshToken,
    }
  },

  getters: {
    isLoggedIn(): boolean {
      return !isNil(this.accessToken)
    },
  },

  actions: {
    async login(payload: AuthPayload): Promise<void> {
      const url = ApiUrls.authTokenGeneration()
      const { data } = await PublicApi.post<AuthData>(url, payload)

      this.accessToken = data.access
      this.refreshToken = data.refresh

      setApiToken(this.accessToken)
      localStorage.setItem(persistentStorageKey, JSON.stringify(this.$state))
    },

    logout(): void {
      setApiToken()
      localStorage.removeItem(persistentStorageKey)
      this.$reset()
    },
  },
})
