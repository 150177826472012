import isNil from 'lodash/isNil'
import { defineStore } from 'pinia'

import { Api, ApiUrls } from '@/api'
import {
  BowlerData,
  BowlerEditableData,
  BowlerMembership,
  BowlerState,
} from '@/store/bowler-app/bowler.types'

export const useBowlerStore = defineStore('bowler', {
  state(): BowlerState {
    return {}
  },

  getters: {
    activeMembership(): BowlerMembership | null {
      if (isNil(this.activeMembershipId)) {
        return null
      }

      return (
        (this.memberships ?? []).find(
          (item) => item.id === this.activeMembershipId,
        ) ?? null
      )
    },
  },

  actions: {
    clear(): void {
      this.$reset()
    },

    async fetch(id: string): Promise<void> {
      const url = ApiUrls.bowler(id)
      const { data } = await Api.get<BowlerData>(url)

      this.id = data.id

      // general
      this.firstName = data.first_name
      this.lastName = data.last_name
      this.birthDate = new Date(data.date_of_birth)
      this.gender = data.gender
      this.firstRegistrationDate = new Date(data.first_registration_date)

      // contact
      this.email = data.email
      this.phoneNumber = data.phone_number
      this.streetAddress = data.street
      this.postalCode = data.postcode
      this.city = data.city
      this.country = data.country

      // membership
      this.memberNumber = data.member_number
      this.isMarketingPermitted = data.is_marketing_permitted
      this.activeMembershipId = data.active_membership_id
      this.memberships = (data.memberships ?? []).map((item) => ({
        id: item.id,
        associationName: item.club?.association?.name_fi,
        clubName: item.club?.name_fi,
        startDate: !isNil(item.start_date)
          ? new Date(item.start_date)
          : undefined,
        endDate: !isNil(item.end_date) ? new Date(item.end_date) : undefined,
      }))

      // stats
      this.currentAverage = data.stats.current_average
      this.previousAverage = data.stats.previous_average
      this.cutScoreGames = data.stats.cut_score_games
      this.seasonGames = data.stats.season_games
      this.currentClass = data.stats.current_class
    },

    async update(id: string, payload: BowlerEditableData): Promise<void> {
      const url = ApiUrls.bowler(id)
      const { data } = await Api.patch<BowlerEditableData>(url, payload)

      this.email = data.email ?? this.email
      this.phoneNumber = data.phone_number ?? this.phoneNumber
      this.streetAddress = data.street ?? this.streetAddress
      this.postalCode = data.postcode ?? this.postalCode
      this.city = data.city ?? this.city
      this.country = data.country ?? this.country
      this.isMarketingPermitted =
        data.is_marketing_permitted ?? this.isMarketingPermitted
    },
  },
})
