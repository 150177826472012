import axios from 'axios'
import isNil from 'lodash/isNil'
import { defineStore } from 'pinia'

import { Api, ApiUrls } from '@/api'
import {
  BowlerSeriesScore,
  BowlerSeriesScoreData,
  BowlerSeriesScoresData,
  BowlerSeriesScoresState,
} from '@/store/bowler-app/bowler-series-scores.types'

function toSeriesScore(item: BowlerSeriesScoreData): BowlerSeriesScore {
  return {
    date: new Date(item.score_date),
    score: item.score,
    scoreLastGame: item.last_game_score,
    gameCount: item.game_count,
    strikeCount: item.strike_count,
    bowlingAlley: item.bowling_alley_name ?? null,
    competitions: item.competition_names as string[],
    gameAverage: item.game_average ?? null,
  }
}

export const useBowlerSeriesScoresStore = defineStore('bowler-series-scores', {
  state(): BowlerSeriesScoresState {
    return {
      count: 0,
      nextUrl: null,
      scores: [],
    }
  },

  actions: {
    fetch: async function (id: string): Promise<void> {
      const url = ApiUrls.bowlerSeriesScores(id)
      const { data } = await Api.get<BowlerSeriesScoresData>(url)

      this.count = data.count
      this.nextUrl = data.next ?? null
      this.scores = data.results.map(toSeriesScore)
    },

    fetchNext: async function (): Promise<void> {
      if (isNil(this.nextUrl)) {
        return
      }

      const { data } = await Api.get<BowlerSeriesScoresData>(this.nextUrl)

      this.count = data.count
      this.nextUrl = data.next ?? null
      this.scores = [...this.scores, ...data.results.map(toSeriesScore)]
    },
  },
})
