import { RouteRecordRaw } from 'vue-router'

import NotFoundView from '@/components/views/NotFoundView.vue'

export default [
  {
    path: '/:pathMatch(.*)*',
    component: NotFoundView,
    meta: {
      isLoginPossible: true,
    },
  },
] as readonly RouteRecordRaw[]
