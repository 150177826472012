import isNil from 'lodash/isNil'
import { defineStore } from 'pinia'

import { ApiUrls, PublicApi } from '@/api'
import { AveragesState } from '@/store/public/averages.types'

export const useAveragesStore = defineStore('averages', {
  state(): AveragesState {
    return {
      count: 0,
      next: null,
      results: null,
    }
  },

  actions: {
    async fetch(bowler: string, club: string): Promise<void> {
      const url = ApiUrls.averages(
        String(bowler ?? '').trim(),
        String(club ?? '').trim(),
      )
      const { data } = await PublicApi.get<AveragesState>(url)

      this.$state = data
    },

    async fetchMore(): Promise<void> {
      if (isNil(this.next)) {
        return
      }

      const { data } = await PublicApi.get<AveragesState>(this.next)

      this.count = data.count
      this.next = data.next ?? null
      this.results = [...(this.results ?? []), ...(data.results ?? [])]
    },
  },
})
