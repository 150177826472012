import { defineStore } from 'pinia'

import { Api, PublicApi } from '@/api'
import { defaultLocale } from '@/i18n'
import i18n from '@/i18n'
import { PreferencesState } from '@/store/preferences.types'

const persistentStorageKey = 'bowling.stores.preferences'

export const usePreferencesStore = defineStore('preferences', {
  state(): PreferencesState {
    const persistentState = JSON.parse(
      localStorage.getItem(persistentStorageKey) ?? '{}',
    )

    return {
      locale: persistentState.locale,
    }
  },

  actions: {
    setLocale(locale?: string): void {
      const selectedLocale = locale ?? defaultLocale

      Api.defaults.headers.common['Accept-Language'] = selectedLocale
      PublicApi.defaults.headers.common['Accept-Language'] = selectedLocale

      i18n.global.locale = selectedLocale
      this.locale = selectedLocale
    },
  },
})
