import { ApiUrls, PublicApi } from '@/api'
import { getErrorMessage } from '@/utils/error-handling'

export default Object.freeze({
  requestRegistration: async (email: string): Promise<boolean> => {
    const url = ApiUrls.bowlerSignUp()
    const data = { email }

    try {
      const response = await PublicApi.post(url, data)
      return response.status === 200
    } catch (e) {
      const message = getErrorMessage(e as Error)
      console.error(`Failed posting a bowler registration request: ${message}`)
      return false
    }
  },
})
