import {
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordNormalized,
  Router,
  createRouter,
  createWebHistory,
} from 'vue-router'

import bowlerAppRoutes from '@/routes/bowler-app'
import notFoundRoutes from '@/routes/not-found'
import publicRoutes from '@/routes/public'
import { useAuthStore } from '@/store/bowler-app/auth'

export default (): Router => {
  const hostname = window?.location?.hostname

  const includeBowlerAppRoutes =
    hostname === process.env.VUE_APP_BOWLER_APP_HOSTNAME
  const includePublicRoutes =
    hostname === process.env.VUE_APP_PUBLIC_PAGES_HOSTNAME

  const routes = []

  if (includeBowlerAppRoutes) {
    routes.push(...bowlerAppRoutes)
  }

  if (includePublicRoutes) {
    routes.push(...publicRoutes)
  }

  routes.push(...notFoundRoutes)

  const router = createRouter({
    history: createWebHistory(),
    routes,
  })

  if (includeBowlerAppRoutes) {
    router.beforeEach(
      async (
        to: RouteLocationNormalized,
        from: RouteLocationNormalized,
        next: NavigationGuardNext,
      ): Promise<void> => {
        const isUserLoggedIn: boolean = useAuthStore().isLoggedIn
        const isAuthRequired: boolean = to.matched.some(
          (value: RouteRecordNormalized): boolean =>
            value.meta.isAuthRequired as boolean,
        )

        if (to.path === '/login' && isUserLoggedIn) {
          next({ path: '/' })
          return
        }

        if (isAuthRequired && !isUserLoggedIn) {
          next({ path: '/login' })
          return
        }

        next()
      },
    )
  }

  return router
}
