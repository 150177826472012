import { ApiUrls, PublicApi } from '@/api'
import { getErrorMessage } from '@/utils/error-handling'

const postPasswordResetData = async (data: object): Promise<boolean> => {
  const url = ApiUrls.userPasswordReset()

  try {
    const response = await PublicApi.post(url, data)
    return response.status === 200
  } catch (e) {
    const message = getErrorMessage(e as Error)
    console.error(`Failed posting password reset data: ${message}`)
    return false
  }
}

export default Object.freeze({
  requestToken: async (email: string): Promise<boolean> =>
    postPasswordResetData({ action: 'create', email }),

  updatePassword: async (token: string, password: string) =>
    postPasswordResetData({ action: 'update', token, password }),

  verifyToken: async (token: string): Promise<boolean> =>
    postPasswordResetData({ action: 'verify', token }),
})
