import axios from 'axios'
import isNil from 'lodash/isNil'
import isNumber from 'lodash/isNumber'

import { useAuthStore } from '@/store/bowler-app/auth'

const DEFAULT_BACKEND_API_URL = 'http://localhost:3000/api/v1'

export const ApiUrls = Object.freeze({
  authTokenGeneration: () => '/auth/token/',
  authTokenVerification: () => '/auth/token/verify/',
  averages: (bowler: string, club: string, page?: number) => {
    const params = new URLSearchParams()

    if (bowler.length > 0) params.set('bowler', bowler)
    if (club.length > 0) params.set('club', club)
    if (isNumber(page)) params.set('page', String(page))

    return `/scoring/averages/search/?${params.toString()}`
  },
  bowler: (id: string | number) => `/organization/bowlers/${id}/`,
  bowlerSeriesScores: (id: string | number) =>
    `/scoring/series-scores/?related_bowler=${id}`,
  bowlerSignUp: () => '/users/register/',
  currentUser: () => '/users/me/',
  competition: (id: string | number) => `/scoring/competitions/${id}/`,
  competitionStandings: (id: string | number) =>
    `/scoring/competitions/${id}/standings/`,
  competitionTeamStandings: (id: string | number) =>
    `/scoring/competitions/${id}/team-standings/`,
  farmClubDeals: () => '/organization/farm-club-deals/',
  leagues: () => '/leagues/leagues/',
  league: (id: string | number) => `/leagues/leagues/${id}/`,
  leagueStandings: (id: string | number) => `/leagues/leagues/${id}/standings/`,
  leagueRound: (id: string | number) => `/leagues/league-rounds/${id}/`,
  leagueRoundStandings: (id: string | number) =>
    `/leagues/league-rounds/${id}/standings/`,
  leagueMatch: (id: string | number) => `/leagues/league-matches/${id}/`,
  leagueMatchStandings: (id: string | number) =>
    `/leagues/league-matches/${id}/standings/`,
  topScoringList: (id: string | number) => `/scoring/top-scoring-lists/${id}/`,
  topScoringLists: () => '/scoring/top-scoring-lists/',
  userPasswordReset: () => '/users/reset-password/',
})

export const PublicApi = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_API_URL ?? DEFAULT_BACKEND_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  transformRequest: (data, headers) => {
    delete headers['Authorization']
    return JSON.stringify(data)
  },
})

export const Api = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_API_URL ?? DEFAULT_BACKEND_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

Api.interceptors.response.use(
  (response) => response,
  (error) => {
    if ([401, 403].includes(error.response.status)) {
      useAuthStore().logout()
      window.location.href = '/login'
    }

    return Promise.reject(error)
  },
)

export const setApiToken = (token?: string): void => {
  delete Api.defaults.headers.common['Authorization']

  if (!isNil(token)) {
    Api.defaults.headers.common.Authorization = `Bearer ${token}`
  }
}
