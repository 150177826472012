import isNil from 'lodash/isNil'
import { defineStore } from 'pinia'

import { ApiUrls, PublicApi } from '@/api'
import {
  League,
  LeagueGroup,
  LeagueMatch,
  LeagueMatchStandings,
  LeagueRound,
  LeagueStandings,
  LeaguesState,
  RoundStandings,
} from '@/store/public/leagues.types'

export const useLeaguesStore = defineStore('leagues', {
  state(): LeaguesState {
    return {
      leagues: [],
      selectedSeason: null,
      selectedLeague: null,
      selectedRound: null,
      selectedMatch: null,
    }
  },

  getters: {
    availableSeasons(): string[] {
      const seasons = (this.leagues ?? []).map((league) => league.season)
      return [...new Set(seasons)].sort().reverse()
    },

    availableGroups(): LeagueGroup[] {
      if (isNil(this.selectedSeason)) {
        return []
      }

      const mapping = (this.leagues ?? [])
        .filter((league) => league.season === this.selectedSeason)
        .reduce((groups, league) => {
          const key = JSON.stringify({
            id: league.league_group_id,
            name: league.league_group_name,
          })

          return {
            ...groups,
            [key]: [...(groups[key] ?? []), league],
          }
        }, {} as { [group: string]: League[] })

      return Object.entries(mapping)
        .map(([key, leagues]) => {
          const group: { id: number; name: string } = JSON.parse(key)
          return { id: group.id, name: group.name, leagues }
        })
        .sort((a, b) => (a.name > b.name ? 1 : -1))
    },
  },

  actions: {
    async fetch(): Promise<void> {
      const url = ApiUrls.leagues()
      const { data } = await PublicApi.get<League[]>(url)

      this.leagues = data
    },

    selectSeason(season?: string): void {
      this.selectedSeason = season ?? null
      this.selectedLeague = null
      this.selectedRound = null
      this.selectedMatch = null
    },

    async selectLeagueById(id?: string | number): Promise<void> {
      if (isNil(id)) {
        this.selectedLeague = null
        return
      }

      const url = ApiUrls.league(id)
      const { data } = await PublicApi.get<League>(url)

      this.selectedLeague = data
      this.selectedRound = null
      this.selectedMatch = null
    },

    async fetchLeagueStandings(): Promise<void> {
      if (isNil(this.selectedLeague)) {
        return
      }

      const url = ApiUrls.leagueStandings(this.selectedLeague.id)
      const { data } = await PublicApi.get<LeagueStandings>(url)

      this.selectedLeague.standings = data
    },

    async selectRoundById(id?: string | number): Promise<void> {
      if (isNil(id)) {
        this.selectedRound = null
        return
      }

      const url = ApiUrls.leagueRound(id)
      const { data } = await PublicApi.get<LeagueRound>(url)

      this.selectedRound = data
      this.selectedMatch = null
    },

    async fetchLeagueRoundStandings(): Promise<void> {
      if (isNil(this.selectedRound)) {
        return
      }

      const url = ApiUrls.leagueRoundStandings(this.selectedRound.id)
      const { data } = await PublicApi.get<RoundStandings>(url)

      this.selectedRound.standings = data
    },

    async selectMatchById(id?: string | number): Promise<void> {
      if (isNil(id)) {
        this.selectedMatch = null
        return
      }

      const url = ApiUrls.leagueMatch(id)
      const { data } = await PublicApi.get<LeagueMatch>(url)

      this.selectedMatch = data
    },

    async fetchLeagueMatchStandings(): Promise<void> {
      if (isNil(this.selectedMatch)) {
        return
      }

      const url = ApiUrls.leagueMatchStandings(this.selectedMatch.id)
      const { data } = await PublicApi.get<LeagueMatchStandings>(url)

      this.selectedMatch.standings = data
    },
  },
})
