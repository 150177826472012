import { RouteRecordRaw } from 'vue-router'

import BowlerProfileView from '@/components/views/bowler-app/BowlerProfileView.vue'
import BowlerRegistrationRequestView from '@/components/views/bowler-app/BowlerRegistrationRequestView.vue'
import BowlerResultsView from '@/components/views/bowler-app/BowlerResultsView.vue'
import DashboardView from '@/components/views/bowler-app/DashboardView.vue'
import LoginView from '@/components/views/bowler-app/LoginView.vue'
import LogoutView from '@/components/views/bowler-app/LogoutView.vue'
import PasswordResetRequestView from '@/components/views/bowler-app/PasswordResetRequestView.vue'
import PasswordResetView from '@/components/views/bowler-app/PasswordResetView.vue'

export default [
  {
    path: '/',
    component: DashboardView,
    meta: {
      isAuthRequired: true,
      isNavAvailable: true,
    },
  },

  {
    path: '/profile',
    component: BowlerProfileView,
    meta: {
      isAuthRequired: true,
      isNavAvailable: true,
    },
  },

  {
    path: '/results',
    component: BowlerResultsView,
    meta: {
      isAuthRequired: true,
      isNavAvailable: true,
    },
  },

  {
    path: '/login',
    component: LoginView,
    meta: {
      isLoginPossible: true,
    },
  },

  {
    path: '/logout',
    component: LogoutView,
    meta: {
      isAuthRequired: true,
      isLoginPossible: true,
    },
  },

  {
    path: '/register',
    component: BowlerRegistrationRequestView,
    meta: {
      isLoginPossible: true,
    },
  },

  {
    path: '/password-reset',
    component: PasswordResetRequestView,
    meta: {
      isLoginPossible: true,
    },
  },

  {
    path: '/password-reset/:token',
    component: PasswordResetView,
  },
] as readonly RouteRecordRaw[]
