import { RouteRecordRaw } from 'vue-router'

import NotFoundView from '@/components/views/NotFoundView.vue'
import AverageView from '@/components/views/public/AverageView.vue'
import CompetitionView from '@/components/views/public/CompetitionView.vue'
import FarmClubDealsView from '@/components/views/public/FarmClubDealsView.vue'
import LeagueDetailView from '@/components/views/public/LeagueDetailView.vue'
import LeagueMatchStandingsView from '@/components/views/public/LeagueMatchStandingsView.vue'
import LeagueRoundStandingsView from '@/components/views/public/LeagueRoundStandingsView.vue'
import LeagueSelectionView from '@/components/views/public/LeagueSelectionView.vue'
import LeagueStandingsView from '@/components/views/public/LeagueStandingsView.vue'
import LeagueView from '@/components/views/public/LeagueView.vue'
import TopScoringListSelectionView from '@/components/views/public/TopScoringListSelectionView.vue'
import TopScoringListView from '@/components/views/public/TopScoringListView.vue'
import i18n from '@/i18n'

export default [
  {
    path: '/averages',
    component: AverageView,
  },

  {
    path: '/competitions/:id',
    component: CompetitionView,
  },

  {
    path: '/farm-club-deals',
    component: FarmClubDealsView,
  },

  {
    path: '/leagues',
    component: LeagueView,
    children: [
      {
        path: '',
        component: LeagueSelectionView,
      },
      {
        path: ':season/',
        component: LeagueSelectionView,
      },
      {
        path: ':season/:id',
        component: LeagueDetailView,
      },
      {
        path: ':season/:id/standings',
        component: LeagueStandingsView,
        meta: {
          breadcrumbLabel: i18n.global.t('leagues.labels.standings'),
        },
      },
      {
        path: ':season/:id/standings/:round',
        component: LeagueRoundStandingsView,
      },
      {
        path: ':season/:id/standings/:round/:match',
        component: LeagueMatchStandingsView,
      },
    ],
  },

  {
    path: '/top-scoring-lists/',
    component: TopScoringListSelectionView,
  },

  {
    path: '/top-scoring-lists/:id',
    component: TopScoringListView,
  },

  {
    path: '/:pathMatch(.*)*',
    component: NotFoundView,
  },
] as readonly RouteRecordRaw[]
