import { defineStore } from 'pinia'

import { ApiUrls, PublicApi } from '@/api'
import {
  TopScoringListBowler,
  TopScoringListData,
  TopScoringListInfo,
  TopScoringListsState,
} from '@/store/public/top-scoring-lists.types'

export const useTopScoringListsStore = defineStore('top-scoring-lists', {
  state(): TopScoringListsState {
    return {
      available: [],
      selected: null,
    }
  },

  actions: {
    async fetch(): Promise<void> {
      const url = ApiUrls.topScoringLists()
      const { data } = await PublicApi.get<TopScoringListInfo[]>(url)

      this.available = data
    },

    async fetchById(id: string): Promise<void> {
      const url = ApiUrls.topScoringList(id)
      const { data } = await PublicApi.get<TopScoringListData>(url)

      const bowlers: TopScoringListBowler[] = data.data.bowlers.map(
        (entry) => ({
          bowler: {
            firstName: entry.first_name,
            lastName: entry.last_name,
            activeClub: entry.active_club,
            ageGroupName: entry.age_group_name,
            gender: entry.gender,
            klass: entry.klass,
          },
          averages: {
            current: entry.current_average,
            previous: entry.previous_average,
          },
          gameCounts: {
            currentSeason: entry.game_count_current_season,
            cutScore: entry.game_count_cut_score,
          },
        }),
      )

      this.selected = {
        id: data.id,
        name: data.name,
        ageGroupName: data.age_group_name,
        gender: data.gender,
        minimumGameCount: data.min_game_count,
        lastUpdatedAt: new Date(data.data.timestamp),
        bowlers,
      }
    },
  },
})
