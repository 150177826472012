import * as dateFns from 'date-fns'
import isNil from 'lodash/isNil'

import i18n, {
  dateFormatLocales,
  defaultDateFormatLocale,
  defaultLongDateFormat,
  defaultLongTimeFormat,
  longDateFormats,
  shortDateFormats,
} from '@/i18n'

const DEFAULT_VALUE = '—'

export const formatBoolean = (value?: boolean | null): string => {
  if (value === null || value === undefined) {
    return DEFAULT_VALUE
  }

  return (value as boolean)
    ? i18n.global.t('formatting.booleans.yes')
    : i18n.global.t('formatting.booleans.no')
}

export const formatDate = (
  date?: Date | null,
  includeTime = false,
  shortDate = false,
): string => {
  const dateFormat: string =
    (shortDate
      ? shortDateFormats.get(i18n.global.locale)
      : longDateFormats.get(i18n.global.locale)) ?? defaultLongDateFormat

  const dateFormatOptions = {
    locale:
      dateFormatLocales.get(i18n.global.locale) ?? defaultDateFormatLocale,
  }

  if (isNil(date) || !dateFns.isValid(date)) {
    return DEFAULT_VALUE
  }

  const formattedDate = dateFns.format(date, dateFormat, dateFormatOptions)

  if (!includeTime) {
    return formattedDate
  }

  const formattedTime = formatTime(date)
  const joinWord = i18n.global.t('formatting.datetime.at')

  return `${formattedDate} ${joinWord} ${formattedTime}`
}

export const formatTime = (date?: Date | null): string => {
  if (isNil(date) || !dateFns.isValid(date)) {
    return DEFAULT_VALUE
  }

  return dateFns.format(date, defaultLongTimeFormat)
}

export const formatFloat = (
  value: string | number | null,
  precision: number,
) => {
  const asFloat = parseFloat(String(value))

  if (isNil(value) || isNaN(asFloat)) {
    return DEFAULT_VALUE
  }

  return asFloat.toFixed(precision).replace('-', '–')
}

export const formatGender = (gender: string | null, plural = false): string => {
  switch (gender?.toUpperCase()) {
    case 'M':
      return plural
        ? i18n.global.t('formatting.genders.males')
        : i18n.global.t('formatting.genders.male')
    case 'F':
      return plural
        ? i18n.global.t('formatting.genders.females')
        : i18n.global.t('formatting.genders.female')
    default:
      return DEFAULT_VALUE
  }
}

export const formatValue = (value?: string | null): string => {
  const asString = !isNil(value) ? String(value).trim() : ''
  return asString.length > 0 ? asString : DEFAULT_VALUE
}
