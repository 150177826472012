import { enGB, fi } from 'date-fns/locale'
import { createI18n } from 'vue-i18n'

import messagesEn from '@/i18n/messages/en.json'
import messagesFi from '@/i18n/messages/fi.json'
import {
  AvailableLocales,
  MessageSchema,
  SupportedLocaleList,
} from '@/i18n/types'

export const supportedLocales: SupportedLocaleList = [
  { label: 'English', value: 'en' },
  { label: 'Suomi', value: 'fi' },
]

export const defaultLocale: string =
  process.env.VUE_APP_I18N_DEFAULT_LOCALE ??
  navigator.language.substring(0, 2) ??
  'en'

export const fallbackLocale: string =
  process.env.VUE_APP_I18N_FALLBACK_LOCALE ?? 'en'

export const localeMessages = {
  en: messagesEn,
  fi: messagesFi,
}

export const defaultLongDateFormat = 'd MMMM yyyy'
export const defaultLongTimeFormat = 'HH:mm'
export const defaultShortDateFormat = 'yyyy-MM-dd'

export const longDateFormats = new Map<string, string>([
  ['en', defaultLongDateFormat],
  ['fi', 'd. MMMM yyyy'],
])

export const shortDateFormats = new Map<string, string>([
  ['en', defaultShortDateFormat],
  ['fi', 'd.M.yyyy'],
])

export const defaultDateFormatLocale: Locale = enGB

export const dateFormatLocales = new Map<string, Locale>([
  ['en', defaultDateFormatLocale],
  ['fi', fi],
])

export default createI18n<[MessageSchema], AvailableLocales>({
  locale: defaultLocale,
  fallbackLocale,
  messages: localeMessages,
})
